<template>
  <b-card>
    <!-- DATAS -->
    <b-row>
      <b-col>
        <label>Mês</label>
        <b-form-select
          :disabled="!rerender"
          v-model="selectedMonth"
          :options="monthOptions"
        ></b-form-select>
      </b-col>
      <b-col>
        <label>Ano</label>
        <b-form-select
          :disabled="!rerender"
          v-model="selectedYear"
          :options="yearOptions"
        ></b-form-select>
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <!-- BENS -->
      <b-col v-if="rerender">
        <h5 class="text-center mb-2">
          {{ `Bens - R$ ${numberToMonetary(response.TotalGoods)}` }}
        </h5>
        <b-row class="m-2" v-for="(good, index) in response.Goods" :key="index">
          <b-col>
            <label>{{ good.item_name }}</label>
          </b-col>
          <b-col>
            <b-form-input
              disabled
              v-money="moneyMask"
              v-model="good.acquisition_value"
            ></b-form-input>
          </b-col>
        </b-row>
      </b-col>

      <!-- COLABORADORES -->
      <b-col v-if="rerender">
        <h5 class="text-center">
          {{
            `Colaboradores - R$ ${numberToMonetary(response.TotalUsersCosts)}`
          }}
        </h5>
        <b-row
          class="m-1"
          v-for="(employee, index) in response.UsersWithCosts"
          :key="index"
        >
          <b-col>
            <label>{{ employee.username }}</label>
          </b-col>
          <b-col>
            <b-form-input
              v-money="moneyMask"
              disabled
              :value="employee.value"
            ></b-form-input>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BFormInput, BFormSelect } from 'bootstrap-vue';

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormSelect,
  },
  data: () => ({
    response: { TotalUsersCosts: 0, TotalGoods: 0 },
    yearOptions: [
      2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025,
    ],
    monthOptions: [
      { value: 1, text: 'Janeiro' },
      { value: 2, text: 'Fevereiro' },
      { value: 3, text: 'Março' },
      { value: 4, text: 'Abril' },
      { value: 5, text: 'Maio' },
      { value: 6, text: 'Junho' },
      { value: 7, text: 'Julho' },
      { value: 8, text: 'Agosto' },
      { value: 9, text: 'Setembro' },
      { value: 10, text: 'Outubro' },
      { value: 11, text: 'Novembro' },
      { value: 12, text: 'Dezembro' },
    ],
    selectedMonth: 1,
    selectedYear: new Date().getFullYear(),
    rerender: true,
    moneyMask: {
      prefix: 'R$ ',
      suffix: '',
      thousands: '.',
      decimal: ',',
      precision: 2,
      masked: false,
    },
  }),
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
  created() {
    this.getCalcCosts();
  },
  watch: {
    selectedMonth: 'getCalcCosts',
    selectedYear: 'getCalcCosts',
  },
  methods: {
    async getCalcCosts() {
      this.rerender = false;
      if (!this.userList) {
        this.$store
          .dispatch('getCalcCosts', {
            workspace_id: this.$store.getters.currentWorkspace.id,
            month: this.selectedMonth,
            year: this.selectedYear,
          })
          .then((resp) => {
            this.rerender = true;
            this.response = resp;
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.getInactivesUsers();
      }
    },
  },
};
</script>
